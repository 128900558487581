/*Navbar*/
.nav {
  width: 100%;
  /* position: absolute;
  z-index: 10; */
  /* top: 0%; */
  text-align: right;
  height: 70px;
  line-height: 70px;
  font-size: 1.9em;
  background: #e9e7e2;
  /* overflow: hidden; */
}
#nav-spacer {
  height: 70px;
  display: none;
}
#hamburger {
  position: absolute;
  right: 15px;
  top: 18px;
  display: none;
}
.menu {
  height: 70px;
  width: 90vw;
  display: flex;
  justify-content: flex-start;
  z-index: 0;
}
.menu a,
.menu-show a {
  clear: right;
  color: black;
  font-family: "Josefin Slab";
  margin: 0px 45px 0px 0px;
  display: flex;
  transition: 0.3s;
  text-decoration: none;
}
.menu a:hover,
.menu-show a:hover {
  color: blue;
}

label {
  margin: 0px 10px 0 0;
  font-size: 2.6em;
  line-height: 70px;
  display: none;
  float: right;
}
#toggle {
  display: none;
}
#toggle:checked + .menu {
  display: block;
}

.home-button-icon {
  height: 80%;
  max-height: 70px;
  margin: 6px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-button-icon:hover {
  fill: blue;
}
#home-button-link {
  width: 70px;
}

#top-bar {
  position: absolute;
  top: 50px;
  left: 70px;
  width: calc(100% - 70px);
  height: 1px;
  background: black;
  z-index: 999;
}

@media only screen and (max-width: 700px) {
  #hamburger {
    display: block;
    cursor: pointer;
  }
  #top-bar {
    width: 0px;
    transition: 0.3s;
  }
  label {
    display: block;
    cursor: pointer;
  }
  .menu {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #e7e7e3;
    height: 70px;
    display: none;
  }
  .menu-show {
    text-align: center;
    width: 100%;
    /* height: 100%; */
    background: #e7e7e3;
    display: block;
    top: 70px;
    position: absolute;
    z-index: 999;
  }
  .menu-show a {
    display: block;
    height: 70px;
    width: 100%;
    background: #e7e7e3;
    border-bottom: 2px solid black;
    /* position: relative;
    z-index: 999; */
  }
  /* #toggle:checked + .menu {
    display: block;
  } */
  .contactInput {
    width: 80%;
  }
  #caption,
  .caption {
    width: 95%;
  }
  #middleText {
    font-size: 1em;
    top: 60%;
  }
  #home-button-link {
    width: 100%;
  }
}
