.cv-holder {
  width: 700px;
  max-width: 100%;
  margin: 50px auto 100px auto;
  padding: 20px;
  border: 1px solid black;
}

.cv-holder h2 {
  margin-bottom: 15px;
  margin-top: 10px;
}
