.fader-slide {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  object-fit: cover;
  object-position: top;
}

.fader-slide-hidden {
  opacity: 0;
  transition: opacity 2100ms ease-in-out 0s;
}
.fader-slide-show {
  /* pointer-events: none; */
  opacity: 0.9;
  transition: opacity 2100ms ease-in-out 0s;
}
