@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css?family=Josefin+Slab&display=swap);
/* 'Roboto Condensed' 'Architects Daughter' 'Source Sans Pro' */

/*IE9*/
/* *::selection {
  background-color: transparent;
}
*::-moz-selection {
  background-color: transparent;
} */
* {
  /* -webkit-user-select: none;
  -moz-user-select: -moz-none; */
  /*IE10*/
  /* -ms-user-select: none;
  user-select: none; */

  /*You just need this if you are only concerned with android and not desktop browsers.*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  padding: 0;
  margin: 0;
  height: 100vh;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* border: 1px solid black; */
}
body {
  background: #f5f5f5;
  height: 100vh;
}
h1 {
  font-family: "Source Sans Pro", sans-serif;
}
h2 {
  font-size: 1.6em;
  font-family: "Josefin Slab";
}
h3 {
  font-size: 14px;
  margin: 2vmin;
  font-family: "Josefin Slab";
}
p {
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-size: 1.4em;
}
a {
  font-family: "Josefin Slab";
  text-decoration: none;
  color: rgb(0, 0, 0);
  outline: none;
}
a:hover {
  /* color: blue; */
}

#middleText {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -0%);
  white-space: nowrap;
  font-size: 3em;
  /* pointer-events: none; */
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  text-align: center;
  margin-top: 90px;
  z-index: 0;
}
.pageLabel p {
  padding: 10px 10px 5px 10px;
}
.pageLabel a:hover {
  color: blue;
}
/*Pages*/

/* .pagesWrapper {
  height: calc(100vh - 93px);
  background: #f5f5f5;
  font-size: 62.5%;
  margin-top: 90px;
} */
.pagesWrapperNoMargin {
  margin-top: 0;
}
#indexPageWrapper {
  font-size: 62.5%;
  background: transparent;
}

/* Galleries */

.hidden {
  display: none;
}
.paintingCloseup {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background: black;
}
.paintingCloseup img {
  object-fit: contain;
  max-height: 100vh;
  max-width: 100%;
}

/*CV*/
#cvText p {
  margin: 3vmin;
  line-height: 25px;
}
#profilePic {
  float: left;
  margin-left: 2vw;
  margin-right: 3vw;
  border: 1px solid black;
  box-shadow: 5px 5px 3px black;
}

/* Contact */
.pageDiv {
  min-height: calc(100vh - 5vh - 70px - 90px);
}
#contactDiv {
  /* background-image: url("./img/4.png"); */
  background-position: center;
  background-size: cover;
  object-fit: cover;
  margin-top: 0;
  padding-bottom: 3vh;
}
#contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactInput {
  background-color: #f5f5f5;
  resize: none;
  width: 50%;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  height: 6vh;
  font-size: 2em;
  border: 1px solid black;
  box-shadow: 5px 5px 3px black;
  margin-top: 1vh;
  padding-left: 1vw;
}
.contactInputText {
  padding-top: 1vh;
  height: 35vh;
}
#formSubmit {
  margin-top: 2vh;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  height: 6vh;
  font-size: 2em;
  border: 1px solid black;
  box-shadow: 5px 5px 3px black;
  width: 50%;
}

/*Navbar*/
.nav {
  width: 100%;
  /* position: absolute;
  z-index: 10; */
  /* top: 0%; */
  text-align: right;
  height: 70px;
  line-height: 70px;
  font-size: 1.9em;
  background: #e9e7e2;
  /* overflow: hidden; */
}
#nav-spacer {
  height: 70px;
  display: none;
}
#hamburger {
  position: absolute;
  right: 15px;
  top: 18px;
  display: none;
}
.menu {
  height: 70px;
  width: 90vw;
  display: flex;
  justify-content: flex-start;
  z-index: 0;
}
.menu a,
.menu-show a {
  clear: right;
  color: black;
  font-family: "Josefin Slab";
  margin: 0px 45px 0px 0px;
  display: flex;
  transition: 0.3s;
  text-decoration: none;
}
.menu a:hover,
.menu-show a:hover {
  color: blue;
}

label {
  margin: 0px 10px 0 0;
  font-size: 2.6em;
  line-height: 70px;
  display: none;
  float: right;
}
#toggle {
  display: none;
}
#toggle:checked + .menu {
  display: block;
}

.home-button-icon {
  height: 80%;
  max-height: 70px;
  margin: 6px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-button-icon:hover {
  fill: blue;
}
#home-button-link {
  width: 70px;
}

#top-bar {
  position: absolute;
  top: 50px;
  left: 70px;
  width: calc(100% - 70px);
  height: 1px;
  background: black;
  z-index: 999;
}

@media only screen and (max-width: 700px) {
  #hamburger {
    display: block;
    cursor: pointer;
  }
  #top-bar {
    width: 0px;
    transition: 0.3s;
  }
  label {
    display: block;
    cursor: pointer;
  }
  .menu {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #e7e7e3;
    height: 70px;
    display: none;
  }
  .menu-show {
    text-align: center;
    width: 100%;
    /* height: 100%; */
    background: #e7e7e3;
    display: block;
    top: 70px;
    position: absolute;
    z-index: 999;
  }
  .menu-show a {
    display: block;
    height: 70px;
    width: 100%;
    background: #e7e7e3;
    border-bottom: 2px solid black;
    /* position: relative;
    z-index: 999; */
  }
  /* #toggle:checked + .menu {
    display: block;
  } */
  .contactInput {
    width: 80%;
  }
  #caption,
  .caption {
    width: 95%;
  }
  #middleText {
    font-size: 1em;
    top: 60%;
  }
  #home-button-link {
    width: 100%;
  }
}


.fader-slide {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  object-fit: cover;
  object-position: top;
}

.fader-slide-hidden {
  opacity: 0;
  transition: opacity 2100ms ease-in-out 0s;
}
.fader-slide-show {
  /* pointer-events: none; */
  opacity: 0.9;
  transition: opacity 2100ms ease-in-out 0s;
}

.element-slider-show {
  opacity: 1;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 45%;
  left: 0;
  transition: 2.3s;
  max-height: 100%;
  overflow: hidden;
}
.element-slider-hidden-top {
  opacity: 0;
  position: absolute;
  top: 40%;
  right: 0;
  transition: 0;
  max-height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.element-slider-hidden-left {
  opacity: 0;
  position: absolute;
  left: 200;
  transition: 0.3s;
}
.slide-text {
  max-height: 100%;
  overflow: hidden;
  font-size: 3.5em;
  color: white;
  text-shadow: 3px 3px 3px black;
}
.slide-button {
  width: 500px;
  padding: 5px;
  text-align: center;
  margin: 0 auto;
  border: 5px solid white;
}
.slide-button:hover {
  transition: 0.3s;
  border-radius: 15px;
  /* background: rgba(200, 200, 200, 0.5); */
}

@media only screen and (max-width: 500px) {
  .element-slider-show {
    top: 40%;
  }
  .element-slider-hidden-top {
    top: 25%;
  }
  .slide-text {
    font-size: 2em;
  }
  .slide-button {
    width: 300px;
    max-width: 80%;
  }
}

/* Painting Details Page */
.painting-details-page {
  padding: 20px 20px 100px 100px;
  min-height: calc(100vh - 184px);
}
.painting-details-content-holder {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
}
.painting-details-img {
  border: 1px solid black;
  margin: 0px 30px 30px 30px;
  /* max-height: 500px; */
  width: 600px;
  max-width: 100%;
  display: inline-block;
}
.painting-details-text-box {
  display: inline-block;
  border: 1px solid black;
  background: #fafafa;
  font-family: "Josefin Slab";
  height: 200px;
  padding: 20px;
  font-size: 1.2em;
}
.painting-title {
  font-style: italic;
  font-size: 20px;
}
.painting-details-img-holder img {
  height: 100%;
}
.painting-details-text-box a:hover {
  color: blue;
}

/* Painting Details Page Meda Queries */
@media only screen and (max-width: 1067px) {
  .painting-details-page {
    padding: 20px 20px 100px 20px;
  }
  .painting-details-img {
    margin: 0 0 20px 0;
  }
  .painting-title {
    font-size: 1.2em;
  }
  .painting-details-text-box {
    width: 500px;
    max-width: 100%;
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 500px) {
  .painting-title {
    /* font-size: 0.8em; */
  }
  .painting-details-text-box {
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 400px) {
  .painting-title {
    /* font-size: 0.8em; */
  }
  .painting-details-text-box {
    font-size: 1em;
  }
}
@media only screen and (max-width: 270px) {
  .painting-details-text-box {
    font-size: 0.8em;
  }
}

.galleryHolderHolder {
  display: block;
  margin: 0 auto;
  min-height: calc(100vh - 184px);
}
.galleryHolder {
  padding: 10px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.painting-card-body {
  height: 372px;
  width: 350px;
  margin: 10px;
  border: 1px solid black;
  transition: 0.3s;
}
.painting-card-body:hover {
  box-shadow: 1px 1px 3px black;
}
.painting-card-img-link {
  display: block;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  /* border: 1px solid red; */
}
.painting-card-img-link img {
  display: block;
  height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.painting-card-info-text {
  font-family: "Roboto Condensed", "Sans Serif";
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #e9e7e2;
  overflow: hidden;
}

@media only screen and (max-width: 775px) {
  .painting-card-body {
    height: 570px;
    width: 80%;
  }
  .painting-card-img-link img {
    height: 500px;
    width: 100%;
  }
  .galleryHolder {
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .galleryHolder {
    padding: 0;
  }
  .painting-card-body {
    margin: 0;
    margin-bottom: 10px;
    height: 570px;
    width: 98%;
  }
}

.cv-holder {
  width: 700px;
  max-width: 100%;
  margin: 50px auto 100px auto;
  padding: 20px;
  border: 1px solid black;
}

.cv-holder h2 {
  margin-bottom: 15px;
  margin-top: 10px;
}

.exhibitions-parent {
  display: block;
  text-align: center;
}
.art-show-holder {
  margin: 0 auto;
}
.art-show-card {
  display: inline-block;
  margin: 50px auto 0px auto;
  max-height: 100vh;
  max-width: 95%;
  border: 1px solid black;
  overflow: hidden;
}
.art-show-card img {
  /* display: block; */
  object-fit: contain;
  max-height: calc(100vh - 80px);
}
.art-show-title {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto Condensed";
  font-size: 1.6em;
}
.exhibitions-list {
  text-align: left;
  width: 95%;
  max-width: 100%;
  margin: 50px auto 100px auto;
  padding: 20px;
  border: 1px solid black;
}
.exhibitions-list p {
  margin-bottom: 10px;
}

/*new 2020*/
.exhibitions-container {
  padding: 20px 40px 0px 40px;
}
.exhibition-viewer {
  /* height: 80vh; */
  /* min-height: 400px; */
  height: auto;
  width: 100%;
  border: 1px solid black;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-position: center;
  background-size: cover;
  margin-bottom: 60px;
  position: relative;
  background-color: white;
}
.exhibition-background {
  position: absolute;
  z-index: -1;
  opacity: 0.75;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.exhibition-description-frame {
  background: #fafafa;
  width: 100%;
  height: auto;
  padding: 20px;
  border: 1px solid black;
  margin-bottom: 20px;
  word-wrap: break-word;
}
.exhibition-images-frame {
  /* height: 40%; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.side-image-frame {
  height: 100%;
  width: 30%;
  border: 1px solid black;
  overflow: hidden;
}
.side-image {
  height: 100%;
  max-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  object-fit: cover;
  object-position: center;
  display: block;
}
.expanded-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.image-exit-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  font-family: sans-serif;
  z-index: 5;
  cursor: pointer;
}
.image-exit-button:hover {
  text-shadow: 0px 0px 5px white;
}
.exhibition-subtitle {
  font-weight: bold;
}
.exhibition-desc {
  text-align: left;
}
.exhibition-copy {
  font-size: 22px;
}
.exhibition-link {
  margin-top: 10px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  text-decoration: underline;
}
.exhibition-link:hover {
  color: blue;
}
@media screen and (max-width: 1000px) {
  .exhibition-viewer {
    flex-direction: column;
    justify-content: space-between;
    /* height: 60vh; */
  }
}
@media screen and (max-width: 700px) {
  .exhibition-viewer {
    padding: 10px;
  }
  .exhibition-title {
    font-size: 18px;
  }
  .exhibition-copy {
    font-size: 14px;
  }
  .exhibition-images-frame {
    height: 70%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .side-image-frame {
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .side-image-frame:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 400px) {
  .exhibition-title {
    font-size: 16px;
  }
  .exhibition-copy {
    font-size: 11px;
  }
}

.contact {
  width: 100%;
  min-height: calc(100vh - 184px);
}
.contact-form-holder {
  height: 100px;
  text-align: center;
  display: block;
  font-size: 1.5em;
  font-family: "Josefin Slab";
  padding: 50px;
  margin: 0 auto;
}
.contact-form-email-address {
  color: blue;
}

.story-carousel {
  /* height: 200px; */
  width: 90%;
  height: 600px;
  max-height: calc(100vh - 10px);
  overflow: hidden;
}
.story-carousel-slide {
  margin: 0 auto;
  width: 90%;
  height: 600px;
  max-height: calc(100vh - 10px);
  display: flex;
  border: 1px solid black;
  position: relative;
  cursor: -webkit-grab;
  cursor: grab;
}
.story-carousel-img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: auto;
  /* position: absolute; */
  right: 0;
  top: 0;
}
.story-carousel-text-holder {
  position: absolute;
  top: 0;
  width: 30%;
  height: 100%;
  /* overflow: scroll; */
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: left;
  padding: 20px;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  text-shadow: 2px 2px 2px black;
}

.bun-story {
  position: fixed;
  background: black;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.bun-story-page {
  height: 100vh;
  position: fixed;
  top: 0;
  overflow: hidden;
  margin: auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-relative {
  position: relative;
}
.bun-story-img {
  height: 100%;
  transition: ease-in-out 0.7s;
  margin: 0 auto;
  object-fit: cover;
  display: block;
  pointer-events: none;
}
.scroll-indicator {
  position: fixed;
  z-index: 2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  color: white;
  top: 40vh;
  margin: auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  transition: 1s;
}
.scroll-indicator-hidden {
  opacity: 0;
  transform: translateY(-70%);
}
@media screen and (max-width: 700px) {
  .scroll-indicator {
    transform: translateX(15%);
    font-size: 18px;
  }
  .scroll-indicator-hidden {
    opacity: 0;
    transform: translate(15%, -70%);
  }
}
.scroll-indicator-down-arrow {
  position: relative;
  margin-top: 10px;
  fill: white;
  height: 40px;
  width: 40px;
  transition: ease-in-out 2s;
}
.arrow-up {
  transform: translateY(-10%);
}
.arrow-down {
  transform: translateY(10%);
}
.color-flash {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 2;
  transition: 2s;
}
.color-flash-inactive {
  opacity: 0;
}
.camera-flash {
  position: fixed;
  height: 100%;
  width: 5%;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
  transition: 0.2s;
  opacity: 0.8;
  box-shadow: 0px -100px 50vw 50vw rgba(255, 255, 255, 0.3);
  right: 0;
}
.camera-flash-inactive {
  opacity: 0;
  box-shadow: 0px 0px 0px 0vw rgba(255, 255, 255, 0.3);
}
.teaTime-pos-1 {
  transform: scale(1.3) translateX(2vw) translateY(-10vh);
}
.teaTime-pos-2 {
  transform: scale(2.5) translateX(-8%) translateY(-30%);
}
.teaTime-pos-3 {
  transform: scale(1) translateX(-0%) translateY(-0%);
}
.teaTime-pos-4 {
  transform: scale(1.8) translateX(-30%) translateY(-20%);
}
.teaTime-pos-5 {
  transform: scale(1.8) translateX(-30%) translateY(20%);
}
.teaTime-pos-6 {
  transform: scale(1.5) translateX(-0%) translateY(-0%);
}
.teaTime-pos-7 {
  transition: 3s;
  transform: scale(2.6) translateX(24%) translateY(-14%);
}
.teaTime-pos-8 {
  transition: 3s;
  opacity: 0;
  transform: scale(2.8) translateX(0%) translateY(-44%);
}
.museum {
  transition: 1s;
  z-index: 1;
}
.museum-pos-1 {
  opacity: 0;
  transform: translateY(-20%);
}
.museum-pos-2 {
  transition: 3s;
  transition-delay: 1500ms;
  opacity: 1;
  transform: scale(1.5) translate(10%, -10%);
}
.museum-pos-3 {
  transform: scale(1.1);
}
.museum-pos-4 {
  transform: scale(1.5);
  transform: translateX(-20%);
}
.museum-pos-5 {
  transform: scale(1.9) translate(-11%, -25%);
}
.museum-pos-6 {
  transform: scale(2.9) translate(-14%, -28%);
}
.museum-pos-7 {
  transform: scale(1) translate(-0%, -0%);
}
.museum-pos-8 {
  opacity: 0;
}
.harrysLR {
  z-index: 1;
}
.harrys-pos-1 {
  transition-delay: 500ms;
  opacity: 0;
}
.harrys-pos-2 {
  transition-delay: 500ms;
  opacity: 1;
  transform: scale(1.8);
}
.harrys-pos-3 {
  /* couch */
  transform: scale(1.2) translate(-14%, -10%);
}
.harrys-pos-4 {
  /* clues */
  transition-delay: 500ms;
  transform: scale(1.2);
}
.harrys-pos-5 {
  transform: scale(1.7) translate(-20%, 20%);
}
.harrys-pos-6 {
  transform: scale(1.7) translate(20%, 23%);
}
.harrys-pos-7 {
  transform: scale(1.7) translate(10%, -35%);
}
.harrys-pos-8 {
  transform: scale(1.9) translate(6%, -15%);
}
.harrys-pos-9 {
  transform: scale(2.1) translate(2%, -30%);
  opacity: 0;
}
.directors-pos-1 {
  opacity: 0;
}
.directors-pos-2 {
  transform: scale(1.2);
}
.directors-pos-3 {
  /* floral */
  transform: scale(1.6) translate(-8%, -30%);
}
.directors-pos-4 {
  /* below bookcase */
  transform: scale(1.5) translate(-16%, 10%);
}
.directors-pos-5 {
  /* bookcase */
  transition: ease-out 3s;
  transform: scale(1.5) translate(-16%, 15%);
}
.directors-pos-6 {
  /*desk*/
  transform: scale(2) translate(18%, -14%);
}
.directors-pos-7 {
  /*chair*/
  transform: scale(2.2) translate(-8.5%, -9%);
}
.directors-pos-8 {
  /*daydream light*/
  transition-delay: 1000ms;
  transition: ease-out 6s;
  transform: scale(1.8) translate(28%, 2%);
}
.directors-pos-9 {
  /*starkly awoken*/
  transition: ease 0.3s;
  transform: scale(1.5) translate(-14%, -16%);
}
.directors-pos-10 {
  /*red bloom*/
  transform: scale(2.7) translate(-10%, -45%);
}
.directors-pos-11 {
  transform: scale(2.7) translate(-100%, -15%);
  opacity: 0;
}
.italian-pos-1 {
  opacity: 0;
  transition: ease 5s;
  transform: scale(2);
}
.italian-pos-2 {
  transition: ease 5s;
  transform: scale(1.5);
}
.italian-pos-3 {
  /*spot the head*/
  transform: scale(1.5) translate(-2%, -25%);
}
.italian-pos-4 {
  /*camera flash right*/
  transform: scale(1.1) translate(-15%, -0%);
}
.italian-pos-5 {
  /*furthest table*/
  transform: scale(2.6) translate(30%, -18%);
}
.italian-pos-6 {
  transform: scale(1);
}
.italian-pos-7 {
  /*cern finds the head*/
  transform: scale(1.5) translate(14%, -25%);
}
.italian-pos-8 {
  transform: scale(1);
}
.italian-pos-9 {
  /* sharp fork */
  transform: scale(2.6) translate(30%, -18%);
}
.italian-pos-10 {
  transform: scale(1.6) translate(-30%, -15%);
  opacity: 0;
}
.italian-pos-11 {
  opacity: 0;
  transform: translate(-30%, -25%);
}
.lydias-pos-1 {
  opacity: 0;
  transform: translate(-55%, -5%);
}
.lydias-pos-2 {
  transform: scale(1.5) translate(-0%, -0%);
}
.lydias-pos-3 {
  /*approach table*/
  transform: scale(1.2) translate(10%, 10%);
}
.lydias-pos-4 {
  /*clock ticks*/
  transform: scale(2.5) translate(-7%, -7%);
}
.lydias-pos-5 {
  transform: translate(-0%, -0%);
}
.lydias-pos-6 {
  /*approach table*/
  transform: scale(1.2) translate(10%, 10%);
}
.lydias-pos-7 {
  /*hearth beckons*/
  transition: ease 4s;
  transform: scale(1.9) translate(2%, 16%);
}
.lydias-pos-8 {
  /*eating*/
  transform: scale(1.9) translate(17%, 16%);
}
.lydias-pos-9 {
  /*center of table*/
  transform: scale(2.8) translate(22%, 16%);
}
.lydias-pos-10 {
  /*lunged forward*/
  transform: scale(1.8) translate(22%, 16%);
}
.lydias-pos-11 {
  /*stand up*/
  transform: scale(1.1) translate(0%, 0%);
}
.lydias-pos-12 {
  /*table cracked*/
  transform: scale(2.3) translate(22%, 16%);
}
.lydias-pos-13 {
  transform: translate(-0%, -0%);
}
.lydias-pos-14 {
  opacity: 0;
  transform: translate(-35%, -5%);
}
.porch-pos-1 {
  opacity: 0;
  transform: scale(1.8);
}
.porch-pos-2 {
  transform: scale(1.8);
}
.porch-pos-3 {
  /*turn startled*/
  transition-duration: 0.3s;
  transform: scale(1.8) translateX(-10%);
}
.porch-pos-4 {
  transform: translate(-20%);
}
.porch-pos-5 {
  transform: scale(1.2) translateX(-50%);
  opacity: 0;
}
.study-pos-1 {
  opacity: 0;
  transform: scale(1.2) translateX(50%);
}
.study-pos-2 {
  transform: scale(1.3);
}
.study-pos-3 {
  /*spacious chairs*/
  transform: scale(1) translateX(20%);
}
.study-pos-4 {
  /*professor stab*/
  transform: scale(2) translateX(-10%);
}
.study-pos-5 {
  transform: scale(1);
}
.study-pos-6 {
  transform: translate(-25%);
}
.study-pos-7 {
  transform: translate(0);
}
.story-image-small {
  transition: 15s;
  transform: scale(0.3);
}
.story-image-small-2 {
  transition: 15s;
  transform: scale(0.1);
  opacity: 0;
}
.teaTime-end-grid-1 {
  transition-delay: 100;
  transform: scale(0.3) translate(-150%, -50%);
}
.museum-end-grid-1 {
  transition-delay: 200;
  transform: scale(0.4) translate(150%, 50%);
}
.harrysLR-end-grid-1 {
  transition-delay: 300;
  transform: scale(0.5) translate(150%, -150%);
}
.directors-end-grid-1 {
  transition-delay: 400;
  transform: scale(0.3) translate(-250%, -225%);
}
.italian-end-grid-1 {
  transition-delay: 500;
  transform: scale(0.2) translate(250%, -195%);
}
.lydias-end-grid-1 {
  transition-delay: 600;
  transform: scale(0.3) translate(-170%, -134%);
}
.porch-end-grid-1 {
  transition-delay: 700;
  transform: scale(0.3) translate(109%, 105%);
}
.study-end-grid-1 {
  transition-delay: 800;
  transform: scale(0.3) translate(-170%, 99%);
}

.teaTime-end-grid-2,
.museum-end-grid-2,
.harrysLR-end-grid-2,
.directors-end-grid-2,
.italian-end-grid-2,
.lydias-end-grid-2,
.porch-end-grid-2,
.study-end-grid-2 {
  transform: translate(-0%, -0%);
}

.bun-story-text-container {
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  z-index: 2;
}
.story-title {
  color: white;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 54px;
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 50px;
  text-shadow: 1px 1px 10px black;
}
.bun-story-text {
  color: white;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-size: 24px;
  width: 40%;
  text-shadow: 2px 2px 5px black;
  padding: 20px;
  background-color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  border: 1px solid white;
}
.bun-text-index {
  font-size: 10px;
  margin-top: 5px;
  text-align: right;
}
.story-credits-container {
  align-self: center;
  margin-bottom: 50vh;
}
.story-credits {
  transition: 1s;
  color: white !important;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  border: 1px solid white;
  align-self: center;
  padding: 20px;
  background-color: black;
}
.story-credits-text {
  color: white !important;
  text-align: center;
  margin-bottom: 10px;
  transition: 1s;
}
.story-credits-title {
  transition: 1s;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
}
.credits-text-1 {
  transition-delay: 0.3s;
}
.credits-text-2 {
  transition-delay: 0.5s;
}
.credits-text-3 {
  transition-delay: 0.7s;
}
.credits-text-4 {
  transition-delay: 1s;
}
.transparent-above {
  opacity: 0;
  transform: translateY(-20px);
}
@media screen and (max-width: 700px) {
  .bun-story-text {
    padding: 10px;
    font-size: 14px;
    width: 50%;
  }
  .story-title {
    font-size: 24px;
  }
}
@media screen and (max-height: 700px) {
  .story-title {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .bun-story-text {
    padding: 10px;
    font-size: 12px;
    width: 50%;
  }
}
.transition-1-sec {
  transition: ease-in-out 1s;
}
.transparent {
  opacity: 0;
  pointer-events: none;
}
.text-show {
  opacity: 1;
}
.text-1 {
  margin-top: 10vh;
}
.text-2-class-1 {
  transform: translateX(-50%);
}
.text-4 {
  margin-bottom: 80vh;
}
.text-6 {
  margin-bottom: 80vh;
}
.text-7 {
  margin-bottom: 60vh;
}
.text-8 {
  margin-bottom: 60vh;
}
.text-13 {
  margin-bottom: 30vh;
}
.text-18 {
  margin-bottom: 40vh;
}
.text-19 {
  margin-bottom: 30vh;
}
.text-20 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30vh;
}
.text-26 {
  margin-bottom: 40vh;
}
.text-27 {
  transition: 0.3s;
}
.text-27-class-1 {
  transform: skew(10deg, 0deg);
}
.text-104 {
  margin-bottom: 50vh;
}

.text-3,
.text-4,
.text-7,
.text-9,
.text-13,
.text-16,
.text-17,
.text-26,
.text-32,
.text-33,
.text-34,
.text-35,
.text-39,
.text-41,
.text-43,
.text-45,
.text-50,
.text-52,
.text-54,
.text-56,
.text-58,
.text-67,
.text-65,
.text-68,
.text-69,
.text-70,
.text-71,
.text-72,
.text-75,
.text-76,
.text-77,
.text-79,
.text-85,
.text-87,
.text-88,
.text-89,
.text-90,
.text-98,
.text-99,
.text-100 {
  align-self: flex-end;
}

.text-4-class-1,
.text-17-class-1,
.text-32-class-1,
.text-35-class-1,
.text-39-class-1,
.text-41-class-1,
.text-43-class-1,
.text-45-class-1,
.text-50-class-1,
.text-52-class-1,
.text-54-class-1,
.text-56-class-1,
.text-58-class-1,
.text-85-class-1,
.text-87-class-1,
.text-89-class-1 {
  transform: translate(150%);
}

.text-10-class-1,
.text-37-class-1,
.text-40-class-1,
.text-42-class-1,
.text-44-class-1,
.text-46-class-1,
.text-48-class-1,
.text-51-class-1,
.text-53-class-1,
.text-55-class-1,
.text-57-class-1,
.text-59-class-1,
.text-63-class-1,
.text-78-class-1,
.text-82-class-1,
.text-84-class-1,
.text-86-class-1,
.text-92-class-1,
.text-94-class-1 {
  transform: translate(-150%);
}

.text-5,
.text-6,
.text-8,
.text-27,
.text-31,
.text-64,
.text-73,
.text-74,
.text-95,
.text-96,
.text-101 {
  width: 100%;
  align-self: center;
}
.text-64 {
  margin-bottom: 30vh;
  text-align: center;
}

.text-5-class-1,
.text-7-class-1,
.text-95-class-1 {
  transform: skewX(10deg);
}
.text-6-class-1,
.text-96-class-1 {
  transform: skewX(-10deg);
}

/*Footer*/
footer {
  height: 100px;
  background: #e9e7e2;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-size: 1.6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
footer footerLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}
footer a {
  font-family: "Source Sans Pro";
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
}
footer a:hover {
  text-decoration: underline;
}
footer p {
  font-family: "Roboto Condensed";
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
}

@media only screen and (max-width: 400px) {
  footer {
    font-size: 1em;
  }
}

